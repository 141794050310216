// import React, { useState, useContext, useEffect } from 'react';
// import Axios from 'axios';
// import { AuthContext } from '../../../AuthContext'
// import { useHistory } from 'react-router-dom';
// // Custom Components
// import CardInput from './CardInput';
// // stripe
// import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

// function CheckoutForm() {

//     const { pricingPlan, currentUser, currentUserTier, updateUserTier, getSubId, updateSubId } = useContext(AuthContext);
//     const history = useHistory();

//     const [payButtonContent, setPayButtonContent] = useState('Start Trial')

//     useEffect(() => {
//       if (!currentUser) {
//           history.push('/signup')
//       }
//     }, [])

//     let price = 0.00
//     let subType = 'ERROR'
//     let isYearly = false
    
//     if (pricingPlan) {
//       price = pricingPlan.price
//       subType = pricingPlan.plan
//       isYearly = pricingPlan.isYearly
//     }

//     const [email, setEmail] = useState('');
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     let fullName = firstName + lastName

//     const stripe = useStripe();
//     const elements = useElements();

//     /* ONE TIME PAYMENTS */
//     // const handleSubmitPay = async (event) => {
//     //     if (!stripe || !elements) {
//     //       // Stripe.js has not yet loaded.
//     //       // Make sure to disable form submission until Stripe.js has loaded.
//     //       return;
//     //     }
    
//     //     const res = await Axios.post('http://localhost:4001/pay', {email: email});
    
//     //     const clientSecret = res.data['client_secret'];
    
//     //     const result = await stripe.confirmCardPayment(clientSecret, {
//     //       payment_method: {
//     //         card: elements.getElement(CardElement),
//     //         billing_details: {
//     //           email: email,
//     //         },
//     //       },
//     //     });
    
//     //     if (result.error) {
//     //       // Show error to your customer (e.g., insufficient funds)
//     //       console.log(result.error.message);
//     //     } else {
//     //       // The payment has been processed!
//     //       if (result.paymentIntent.status === 'succeeded') {
//     //         console.log('Money is in the bank!');
//     //         // Show a success message to your customer
//     //         // There's a risk of the customer closing the window before callback
//     //         // execution. Set up a webhook or plugin to listen for the
//     //         // payment_intent.succeeded event that handles any business critical
//     //         // post-payment actions.
//     //       }
//     //     }
//     // };

//     const handleSubmitSub = async (event) => {

//         if (firstName === '' || lastName === '' || email === '') {
//           alert('Make sure all fields are filled')
//           return;
//         }

//         setPayButtonContent('')
//         if (!pricingPlan) {
//           alert('no pricing plan detected')
//           setPayButtonContent('Start Trial')
//           return;
//         }

//         if (!stripe || !elements) {
//           // Stripe.js has not yet loaded.
//           // Make sure to disable form submission until Stripe.js has loaded.
//           alert('payment form has not yet loaded')
//           setPayButtonContent('Start Trial')
//           return;
//         }
    
//         const result = await stripe.createPaymentMethod({
//           type: 'card',
//           card: elements.getElement(CardElement),
//           billing_details: {
//             email: email,
//             name: fullName,
//           },
//         });
    
//         if (result.error) {
//           console.log(result.error.message);
//           alert('There has been an error, please try again')
//           setPayButtonContent('Start Trial')
//         } else {

//           let tierNum
//           if (subType === 'BASIC') {
//             tierNum = 1
//           } else if (subType === 'PLUS') {
//             tierNum = 2
//           }

//           try {
//             const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sub`, {'payment_method': result.paymentMethod.id, 'email': email, 'pricingPlan': pricingPlan.plan, 'isYearly': pricingPlan.isYearly});

//             //const {client_secret, status, id} = res.data;
//             const {id} = res.data;
      
//             // if (status === 'requires_action') {
//             //   stripe.confirmCardPayment(client_secret).then(function(result) {
//             //     if (result.error) {
//             //       console.log('There was an issue!');
//             //       alert('There was an issue, card was declined! Please try again or contact routorainc@gmail.com')
//             //       console.log(result.error);
//             //       // Display error message in your UI.
//             //       // The card was declined (i.e. insufficient funds, card has expired, etc)
//             //     } else {
//             //       // console.log('Successful Subscription, client secret: ', client_secret);
//             //       postSuccessSub(tierNum, id)
//             //       // Show a success message to your customer
//             //     }
//             //   });
//             // } else {
//             //   // console.log('Successful Subscription, client secret: ', client_secret);
//             //   postSuccessSub(tierNum, id)
//             //   // No additional information was needed
//             //   // Show a success message to your customer
//             // }

//             postSuccessSub(tierNum, id)

//           } catch(err) {
//             console.log(err)
//             alert('There was an issue, please try again or contact routorainc@gmail.com')
//           }
//           setPayButtonContent('Start Trial')
//         }
//     };

//     async function postSuccessSub(tierNum, id) {

//       // if user already has a subscription
//       // *** WHEN CHARGING FOR TIER 1, MAKE BELOW > 0 ***
//       if (currentUserTier > 1) {

//         const subId = await getSubId(currentUser)
//         const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/deletesub`, {'subId': subId});
//         const {status} = res.data;
//         if (status === 'canceled') {
//           console.log('cancelation success')
//         } else {
//           console.log('cancelation ERROR')
//           alert('Cancelation Error')
//         }

//       }

//       updateUserTier(currentUser, tierNum)
//       updateSubId(currentUser, id)
//       if (tierNum == 1) {
//         history.push('/welcome-basic') 
//       } else {
//         history.push('/welcome-plus') 
//       }
//     }

//     let buttonDisplay = ( <div></div>)
//     if (payButtonContent === 'Start Trial') {
//       buttonDisplay = (
//         <h1 className="text-center my-2">{payButtonContent}</h1>
//       )
//     } else {
//         buttonDisplay = (
//           <div class="loading-container currLocation">
//               <div class="loader">
//                   <div class="loading-circle"></div>
//               </div>
//           </div>
//         )
//     }

//     return (
//         <div style={{ height: 420 }} className="relative flex flex-col items-center mt-10 p-10 bg-white rounded-3xl shadow-lg border-gray-200 border-2 hover:shadow-xl">
//             <div className="flex items-center">
//                 <div className="text-4xl :text-4xl text-gray-700 font-bold leading-snug tracking-tight mb-1 mr-1">{price}</div>
//                 <div className="md:text-1xl text-gray-500 mt-3">/ month</div>
//             </div>
//             { isYearly && 
//                 <div className="flex items-center justify-center mb-2">
//                     <p className="md:text-sm text-sm text-gray-500">${173.99} Billed Annually</p>
//                     <h5 className="annual-deal-message">SAVED 30%</h5>
//                 </div>
//             }
//             <div className="md:text-1xl text-gray-500"><span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-myteal-500">{subType}</span> SUBSCRIPTION - <span className="font-extrabold">7 DAY</span> FREE TRIAL</div>
//             <div className="flex w-full">
//               <input style={{ width: '48%' }} onChange={(e) => setFirstName(e.target.value)} id="email-firstname" className="StripeOtherInput mt-5 mr-5" placeholder="First name" required />
//               <input style={{ width: '48%' }} onChange={(e) => setLastName(e.target.value)} id="email-lastname" className="StripeOtherInput mt-5 ml-auto" placeholder="Last name" required />
//             </div>
//             <input onChange={(e) => setEmail(e.target.value)} id="email-payment" type="email" className="StripeOtherInput my-5" placeholder="Email address" required />
//             <CardInput></CardInput>
//             <div onClick={handleSubmitSub} className="font-semibold mt-5 rounded h-10 w-72 text-white cursor-pointer bg-myteal-500 hover:bg-myteal-600">
//                 {buttonDisplay}
//             </div>
//         </div>
//     );
// }

// export default CheckoutForm;


/* global fbq */
import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import { AuthContext } from '../../../AuthContext'
import { useHistory } from 'react-router-dom';
import CountryMatch from './CountryCodes.js'
// Custom Components
import CardInput from './CardInput';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);


function CheckoutForm() {

    const { setPricingPlan, pricingPlan, currentUser, currentUserTier, updateUserTier, getSubId, updateSubId, checkSubStatus, getAffiliateCodes, trackPromoCodeUsage } = useContext(AuthContext);
    const history = useHistory();

    const [payButtonContent, setPayButtonContent] = useState('Start Trial')
    const [openPromoCode, setOpenPromoCode] = useState(false)
    const [validCode, setValidCode] = useState(false)

    const [promoCodeContent, setPromoCodeContent] = useState('');
    function onPromoCodeChange(e) {
        setPromoCodeContent(e.target.value)
    }
    async function submitPromoCode() {

      if (currentUser) {

        // check subscription status on stripe
        let subStatus = 'active'
        if (currentUserTier > 1) {
            subStatus = await checkSubStatus(currentUser)
            console.log('Subscription Status:', subStatus)
            if (subStatus == 'canceled') {
                updateUserTier(currentUser, 0)
                updateSubId(currentUser, null)
            }
        }

        if (promoCodeContent == process.env.REACT_APP_PROMO_CODE_PLUS) {
          updateUserTier(currentUser, 2)
          history.push('/welcome-plus')
        } else if (promoCodeContent == process.env.REACT_APP_PROMO_CODE_BASIC) {
            updateUserTier(currentUser, 1)
            history.push('/welcome-basic')
        } else {

          // retrieve promo codes
          const PROMO_CODES = await getAffiliateCodes()

          // if promo code exists, else alert that code not valid
          if (PROMO_CODES[promoCodeContent]) {

            setPricingPlan({plan: 'PROMO', price: PROMO_CODES[promoCodeContent].price, isYearly: PROMO_CODES[promoCodeContent].isYearly})

            setOpenPromoCode(false)
            setValidCode(true)

          } else {
              alert('Sorry, Invalid Promo Code')
          }
        }

      } else {
        history.push('/signin')
      }
    }

    useEffect(() => {
      if (!currentUser) {
          history.push('/signup')
      }

      console.log(pricingPlan)
    }, [])

    let price = 0.00
    let subType = 'ERROR'
    let isYearly = false
    
    if (pricingPlan) {
      price = pricingPlan.price
      subType = pricingPlan.plan
      isYearly = pricingPlan.isYearly
    }

    let yearlyDiscount = 27
    try {
      if (pricingPlan.plan == 'PROMO') {
        // pricingPlan.price is the actual price
        // 240 (20*12) is the full
        // find the percentage discount
        const fullPrice = 240;
        const discount = ((fullPrice - pricingPlan.price) / fullPrice) * 100;
        yearlyDiscount = Math.round(discount);

        // delete this after launch (just rounding up to match email campaign)
        if (yearlyDiscount == 38) {
          yearlyDiscount = 40
        }
      }
    } catch (error) {
      console.log(error)
      history.push('/pricing')
    }

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    let fullName = firstName + ' ' + lastName

    // billing address
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    /* ONE TIME PAYMENTS */
    // const handleSubmitPay = async (event) => {
    //     if (!stripe || !elements) {
    //       // Stripe.js has not yet loaded.
    //       // Make sure to disable form submission until Stripe.js has loaded.
    //       return;
    //     }
    
    //     const res = await Axios.post('http://localhost:4001/pay', {email: email});
    
    //     const clientSecret = res.data['client_secret'];
    
    //     const result = await stripe.confirmCardPayment(clientSecret, {
    //       payment_method: {
    //         card: elements.getElement(CardElement),
    //         billing_details: {
    //           email: email,
    //         },
    //       },
    //     });
    
    //     if (result.error) {
    //       // Show error to your customer (e.g., insufficient funds)
    //       console.log(result.error.message);
    //     } else {
    //       // The payment has been processed!
    //       if (result.paymentIntent.status === 'succeeded') {
    //         console.log('Money is in the bank!');
    //         // Show a success message to your customer
    //         // There's a risk of the customer closing the window before callback
    //         // execution. Set up a webhook or plugin to listen for the
    //         // payment_intent.succeeded event that handles any business critical
    //         // post-payment actions.
    //       }
    //     }
    // };

    const handleSubmitSub = async (event) => {

        if (firstName === '' || lastName === '' || email === '') {
          alert('Make sure all fields are filled')
          return;
        }

        setPayButtonContent('')
        if (!pricingPlan) {
          alert('no pricing plan detected')
          setPayButtonContent('Start Trial')
          return;
        }

        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          alert('payment form has not yet loaded')
          setPayButtonContent('Start Trial')
          return;
        }
    
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
            name: fullName,
          },
        });
    
        if (result.error) {
          console.log(result.error.message);
          alert('There has been an error, please try again')
          setPayButtonContent('Start Trial')
        } else {

          let tierNum
          if (subType === 'BASIC') {
            tierNum = 1
          } else if (subType === 'PLUS' || subType === 'PROMO') {
            tierNum = 2
          }

          try {
            console.log(process.env.REACT_APP_AXIOS_BASE_URL)
            const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/sub`, {
              'payment_method': result.paymentMethod.id, 
              'email': email, 
              'pricingPlan': pricingPlan.plan,
              'price': pricingPlan.price,
              'isYearly': pricingPlan.isYearly,
              'name': fullName
            });

            //const {client_secret, status, id} = res.data;
            const {id} = res.data;
      
            // if (status === 'requires_action') {
            //   stripe.confirmCardPayment(client_secret).then(function(result) {
            //     if (result.error) {
            //       console.log('There was an issue!');
            //       alert('There was an issue, card was declined! Please try again or contact routorainc@gmail.com')
            //       console.log(result.error);
            //       // Display error message in your UI.
            //       // The card was declined (i.e. insufficient funds, card has expired, etc)
            //     } else {
            //       // console.log('Successful Subscription, client secret: ', client_secret);
            //       postSuccessSub(tierNum, id)
            //       // Show a success message to your customer
            //     }
            //   });
            // } else {
            //   // console.log('Successful Subscription, client secret: ', client_secret);
            //   postSuccessSub(tierNum, id)
            //   // No additional information was needed
            //   // Show a success message to your customer
            // }

            postSuccessSub(tierNum, id)

          } catch(err) {
            console.log(err)
            alert('There was an issue, please try again or contact info@routora.com')
          }
          setPayButtonContent('Start Trial')
        }
    };

    async function postSuccessSub(tierNum, id) {

      // facebook tracking
      try {
        if (window.fbq) {
          window.fbq('track', 'StartTrial', {
            value: pricingPlan.price, // This uses a standard fb parameter 'value' to indicate the price.
            currency: 'USD', // Standard fb parameter to specify the currency type.
            pricing_plan: pricingPlan.plan, // Custom parameter to specify the type of pricing plan.
            is_yearly: pricingPlan.isYearly // Custom parameter to specify monthly or yearly trial sub.
          });
        }
      } catch (error) {
        console.log('Error in tracking trial start in FB Pixel', error);
      }

      // google tracking
      try {
        // Send the trial start event to Google Analytics (and ultimately Google Ads) after the user is successfully created
        window.gtag && window.gtag('event', 'trial_start', {
          'event_category': 'Trial',
          'event_label': 'Trial Started',
          'value': 20
        });
        console.log('New Trial Start')
      } catch (error) {
        console.log('Error in tracking trial start in Google Analytics', error);
      }

      // track promo code if used
      if (validCode) {
        try {
          trackPromoCodeUsage(promoCodeContent, currentUser.email)
        } catch (error) {
          console.log('Was not able to track promo code usage')
        }
      }

      // amplitude tracking
      const eventProperties = {
        subscription_id: id,
        value: pricingPlan.price,
        is_yearly: pricingPlan.isYearly
      };
      amplitude.track('Trial Started', eventProperties);
      

      // if user already has a subscription
      // *** WHEN CHARGING FOR TIER 1, MAKE BELOW > 0 ***
      if (currentUserTier > 1) {

        const subId = await getSubId(currentUser)
        const res = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/deletesub`, {'subId': subId});
        const {status} = res.data;
        if (status === 'canceled') {
          console.log('cancelation success')
        } else {
          console.log('cancelation ERROR')
          alert('Cancelation Error')
        }

      }

      updateUserTier(currentUser, tierNum)
      updateSubId(currentUser, id)
      if (tierNum == 1) {
        history.push('/welcome-basic') 
      } else {
        history.push('/welcome-plus') 
      }
    }

    let buttonDisplay = ( <div></div>)
    if (payButtonContent === 'Start Trial') {
      buttonDisplay = (
        <h1 className="text-center my-2">{payButtonContent}</h1>
      )
    } else {
        buttonDisplay = (
          <div class="loading-container starttrial">
              <div class="loader">
                  <div class="loading-circle"></div>
              </div>
          </div>
        )
    }

    return (
        <div className="checkoutinfo py-5 mb-10 px-12">
            {subType == 'PROMO' ?
              <div className="text-xl font-medium text-gray-500">Try Routora Web <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-myteal-500">PLUS</span></div>
              :
              <div className="text-xl font-medium text-gray-500">Try Routora Web <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-myteal-500">{subType}</span></div>
            }
            <div className="text-4xl text-gray-700 font-bold">7 Days Free</div>
            {isYearly ? 
              <div className="flex">
                  <div className="text-1xl font-medium text-gray-500">Then ${price} per year</div>
                  <h5 className="annual-deal-message">SAVED {yearlyDiscount}%</h5>
              </div>
              :
              <div className="text-1xl font-medium text-gray-500">Then ${price} per month</div>
            }
            

            <p className="checkout-subtitles mt-10 text-gray-500">Cardholder Information</p>
            <div className="cardholder-info mb-1">
              <div className="flex w-full">
                <input onChange={(e) => setFirstName(e.target.value)} id="email-firstname" className="StripeOtherInput" placeholder="First name" required />
                <input onChange={(e) => setLastName(e.target.value)} id="email-lastname" className="StripeOtherInput" placeholder="Last name" required />
              </div>
              <input onChange={(e) => setEmail(e.target.value)} id="email-payment" type="email" className="StripeOtherInput" placeholder="Email address" required />
            </div>

            {(!openPromoCode && !validCode) && 
              <p onClick={() => setOpenPromoCode(true)} className="text-xs w-20 text-blue-500 cursor-pointer mb-5 underline">Promo code?</p>
            }
            {validCode && 
              <p className="text-xs text-myteal-500 mb-5">Promo code successful ✓</p>
            }

            {openPromoCode && 
              <div className="flex mt-4">
                <input value={promoCodeContent} onChange={onPromoCodeChange} id="promo-code" className="StripeOtherInput" placeholder="Promo code" />
                <div onClick={submitPromoCode} id="promo-code-submit" className="text-xs text-white bg-blue-500 hover:bg-blue-600 cursor-pointer flex items-center justify-center">Apply</div>
              </div>
            }

            {/* <p className="checkout-subtitles mt-5 text-gray-500">Billing Address</p>
            <div className="cardholder-info mb-5">
              <input onChange={(e) => setLine1(e.target.value)} id="address-1" className="StripeOtherInput" placeholder="Address line 1" required />
              <div className="flex w-full">
                <input onChange={(e) => setLine2(e.target.value)} id="address-2" className="StripeOtherInput" placeholder="Address line 2" />
                <input onChange={(e) => setCity(e.target.value)} id="address-city" className="StripeOtherInput" placeholder="City" />
              </div>
              <div className="flex w-full">
                <input onChange={(e) => setState(e.target.value)} id="address-state" className="StripeOtherInput" placeholder="State" required />
                <input onChange={(e) => setCountry(e.target.value)} id="address-country" className="StripeOtherInput" placeholder="Country" required />
                <input onChange={(e) => setZip(e.target.value)} id="address-zip" className="StripeOtherInput" placeholder="ZIP" required />
              </div>
            </div> */}

            <p className="checkout-subtitles mt-5 text-gray-500">Card Information</p>
            <CardInput></CardInput>

            <div className="w-full border-t border-gray-300 mt-10">
                <div className="border-t-0 h-0.5" />
            </div>
            <div className="w-full flex mt-3 text-gray-500">
                <p className="w-2/4 text-left">Total after trial</p>
                <p className="w-2/4 text-right">{isYearly && <span><span className="line-through text-gray-300">$240</span><span> </span></span>}${price}</p>
            </div>
            <div className="w-full flex mt-3 text-gray-600 font-semibold">
                <p className="w-2/4 text-left">Total due today</p>
                <p className="w-2/4 text-right">$0.00</p>
            </div>

            <div onClick={handleSubmitSub} className="font-semibold mt-10 mb-5 rounded py-1 w-full text-white cursor-pointer bg-blue-500 hover:bg-blue-600">
                {buttonDisplay}
            </div>
            <div className="flex justify-center text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="padlock"><path fill="#7F7F7F" d="M17,9V7c0-2.8-2.2-5-5-5S7,4.2,7,7v2c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-7C20,10.3,18.7,9,17,9z M9,7c0-1.7,1.3-3,3-3s3,1.3,3,3v2H9V7z M13.1,15.5c0,0-0.1,0.1-0.1,0.1V17c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1.4c-0.6-0.6-0.7-1.5-0.1-2.1c0.6-0.6,1.5-0.7,2.1-0.1C13.6,13.9,13.7,14.9,13.1,15.5z"/></svg>
              <p class="text-sm ml-1">Secure Checkout</p>
              <p class="text-sm mx-2">|</p>
              <p class="text-sm mr-1">Powered by</p>
              <svg class="mt-1" focusable="false" width="33" height="15" role="img" fill="currentColor" aria-labelledby="stripe-title"><title id="stripe-title">Stripe</title><g fill-rule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
            </div>

        </div>
    );
}

export default CheckoutForm;