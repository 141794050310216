import { createContext, useState, useEffect } from "react";
import firebase from 'firebase/compat/app'
import firebaseAuth from 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, getDoc, updateDoc, FieldValue } from "firebase/firestore";

import { useHistory } from 'react-router-dom';

import React from 'react';
import Axios from 'axios';

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

// initial creation of context, will be placed inside useContext() in other components
export const AuthContext = createContext(null);

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: "gs://romioauth.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
  })
const db = firebase.firestore()
const storage = getStorage(app);

// function to get all the business emails
// businessEmails()
// function businessEmails() {
//   let emailArr = []
//   let fullEmailArr = []
//   console.log('***** DATA OF EACH DOCUMENT *****')
//   db.collection("users").get().then(function(querySnapshot) {
//     querySnapshot.forEach(function(doc) {
//         // doc.data() is never undefined for query doc snapshots
//         let email = doc.id
//         let email1 = email.split('@')[1]
        
//         let index = email1.length-1
//         for (let i = email1.length-2; i >= 0; i--) {
//           if (email1.substring(i,i+1) == '.') {
//             index = i
//           }
//         }

//         let email2 = email1.substring(0,index)
//         if (email2 != 'gmail') {
//           emailArr.push(email2)
//           fullEmailArr.push(email)
//           console.log(email2)
//         }
        
//     });
//   });

//   console.log(emailArr)
//   console.log(fullEmailArr)
// }

// function to get ALL the emails on firebase
// allEmails()
// function allEmails() {
//   let fullEmailArr = []
//   console.log('***** DATA OF EACH DOCUMENT *****')
//   db.collection("users").get().then(function(querySnapshot) {
//     querySnapshot.forEach(function(doc) {
//         // doc.data() is never undefined for query doc snapshots
//         let email = doc.id
//         let name = doc.data().name

//         fullEmailArr.push({email: email, name: name})
        
//     });
//   });

//   return fullEmailArr
// }

// function to get a breakdown of onboarding breakdown
// console.log('Breakdown of Onboarding Questions')
// onboardingBreakdown()
// function onboardingBreakdown() {
//   let medium = {
//     google: 0,
//     article: 0,
//     friend: 0,
//     ad: 0,
//     email: 0,
//     social: 0,
//     other: 0
//   };
//   let segment = {
//     delivery: 0,
//     personal: 0,
//     travel: 0,
//     realestate: 0,
//     fieldservice: 0,
//     sales: 0,
//     healthcare: 0,
//     other: 0
//   };

//   db.collection("users").where("onboarding", "!=", null).get().then(function(querySnapshot) {
//     querySnapshot.forEach(function(doc) {
//       let data = doc.data();
//       if (data.onboarding && data.onboarding.medium) {
        
//         if (data.onboarding.medium == 'Google') {
//           medium.google = medium.google + 1
//         } else if (data.onboarding.medium == 'Article/Blog') {
//           medium.article = medium.article + 1
//         } else if (data.onboarding.medium == 'Friend/Colleague') {
//           medium.friend = medium.friend + 1
//         } else if (data.onboarding.medium == 'Ad') {
//           medium.ad = medium.ad + 1
//         } else if (data.onboarding.medium == 'Email') {
//           medium.email = medium.email + 1
//         } else if (data.onboarding.medium == 'Social Media') {
//           medium.social = medium.social + 1
//         } else if (data.onboarding.medium == 'Other') {
//           medium.other = medium.other + 1
//         }

//       }
      
//       if (data.onboarding && data.onboarding.segment) {
        
//         if (data.onboarding.segment == 'Delivery') {
//           segment.delivery = segment.delivery + 1
//         } else if (data.onboarding.segment == 'Personal Use') {
//           segment.personal = segment.personal + 1
//         } else if (data.onboarding.segment == 'Travel') {
//           segment.travel = segment.travel + 1
//         } else if (data.onboarding.segment == 'Real Estate') {
//           segment.realestate = segment.realestate + 1
//         } else if (data.onboarding.segment == 'Field Service') {
//           segment.fieldservice = segment.fieldservice + 1
//         } else if (data.onboarding.segment == 'Sales') {
//           segment.sales = segment.sales + 1
//         } else if (data.onboarding.segment == 'Health Care') {
//           segment.healthcare = segment.healthcare + 1
//         } else if (data.onboarding.segment == 'Other') {
//           segment.other = segment.other + 1
//         }
        
//       }
//     });

//     console.log('Medium breakdown:', medium);
//     console.log('Segment breakdown:', segment);
//   })
//   .catch(function(error) {
//     console.error("Error getting documents: ", error);
//   });

//   return true; // This will return immediately, not wait for the database operation
// }

// Export the onboarding data at an individual level
// exportOnboardingBreakdownToCSV();
// function exportOnboardingBreakdownToCSV() {
//   // Fetch the data from Firebase
//   db.collection("users").where("onboarding", "!=", null).get()
//     .then(function(querySnapshot) {
//       let usersData = [];
//       querySnapshot.forEach(function(doc) {
//         let data = doc.data();
//         let userRecord = {
//           email: doc.id,
//           subscription_id: data.subscription_id,
//           createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
//           medium: data.onboarding.medium || 'N/A',
//           segment: data.onboarding.segment || 'N/A',
//           organization: data.onboarding.organization || 'N/A'
//         };
//         usersData.push(userRecord);
//       });

//       // Sort the data by createdAt in descending order
//       usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//       // Format the data into CSV
//       let csvContent = "data:text/csv;charset=utf-8,";
//       csvContent += "Email,subscription_id,Created At,Medium,Segment,Organization\n";
//       usersData.forEach(function(user) {
//         let row = `${user.email},${user.subscription_id},${user.createdAt},${user.medium},${user.segment},${user.organization}`;
//         csvContent += row + "\n";
//       });

//       // Trigger a download of the CSV file
//       let encodedUri = encodeURI(csvContent);
//       let link = document.createElement("a");
//       link.setAttribute("href", encodedUri);
//       link.setAttribute("download", "onboarding_breakdown.csv");
//       document.body.appendChild(link); // Required for FF

//       link.click();
//       document.body.removeChild(link); // Clean up
//     })
//     .catch(function(error) {
//       console.error("Error getting documents: ", error);
//     });
// }

// Export signup count by month (MoM growth)
// exportMonthlySignupsToCSV();
// function exportMonthlySignupsToCSV() {
//   // Fetch all user data from Firebase
//   db.collection("users").get()
//     .then(function(querySnapshot) {
//       let monthlySignups = {};

//       querySnapshot.forEach(function(doc) {
//         let data = doc.data();
//         if (data.createdAt) {
//           let date = data.createdAt.toDate();
//           let yearMonth = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}`;

//           if (!monthlySignups[yearMonth]) {
//             monthlySignups[yearMonth] = 0;
//           }
//           monthlySignups[yearMonth]++;
//         }
//       });

//       // Format the data into CSV
//       let csvContent = "data:text/csv;charset=utf-8,";
//       csvContent += "Year-Month,Signups\n";

//       for (let yearMonth in monthlySignups) {
//         let row = `${yearMonth},${monthlySignups[yearMonth]}`;
//         csvContent += row + "\n";
//       }

//       // Trigger a download of the CSV file
//       let encodedUri = encodeURI(csvContent);
//       let link = document.createElement("a");
//       link.setAttribute("href", encodedUri);
//       link.setAttribute("download", "monthly_signups.csv");
//       document.body.appendChild(link); // Required for FF

//       link.click();
//       document.body.removeChild(link); // Clean up
//     })
//     .catch(function(error) {
//       console.error("Error getting documents: ", error);
//     });
// }

// Function to export cancelation feedback data to CSV
// exportCancelationDataToCSV();
// function exportCancelationDataToCSV() {
//   // Fetch all user data with cancellation field from Firebase
//   db.collection("users").where("cancelation", "!=", null).get()
//     .then(function(querySnapshot) {
//       let usersData = [];
//       querySnapshot.forEach(function(doc) {
//         let data = doc.data();
//         let userRecord = {
//           email: doc.id,
//           name: data.name || 'N/A',
//           createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
//           feedback: data.cancelation?.feedback || 'N/A',
//           reason: data.cancelation?.reason || data.cancelation?.segment || 'N/A'
//         };
//         usersData.push(userRecord);
//       });

//       // Sort the data by createdAt in descending order
//       usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//       // Format the data into CSV
//       let csvContent = "data:text/csv;charset=utf-8,";
//       csvContent += "Email,Name,Created At,Feedback,Reason\n";
//       usersData.forEach(function(user) {
//         let row = `${user.email},${user.name},${user.createdAt},${user.feedback},${user.reason}`;
//         csvContent += row + "\n";
//       });

//       // Trigger a download of the CSV file
//       let encodedUri = encodeURI(csvContent);
//       let link = document.createElement("a");
//       link.setAttribute("href", encodedUri);
//       link.setAttribute("download", "cancelation_data.csv");
//       document.body.appendChild(link); // Required for FF

//       link.click();
//       document.body.removeChild(link); // Clean up
//     })
//     .catch(function(error) {
//       console.error("Error getting documents: ", error);
//     });
// }


// function to retrieve all current subscribers from the database
// exportSubscribersToCSV();
// async function exportSubscribersToCSV() {
//   try {
//     const querySnapshot = await db.collection("users")
//       .where("tier", "==", 2)
//       .get();

//     let usersData = [];
//     for (const doc of querySnapshot.docs) {
//       const data = doc.data();
//       let status = 'N/A'; // Default status when no subscription_id is present
//       if (data.subscription_id) {
//         // Check if the subscription is active
//         status = await checkSubscriptionStatus(data.subscription_id);
//       }

//       const userRecord = {
//         email: doc.id,
//         name: data.name || 'N/A',
//         createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : 'N/A',
//         tier: data.tier || 'N/A',
//         subscription_id: data.subscription_id || 'N/A',
//         status
//       };
//       usersData.push(userRecord);
//     }

//     // Sort the data by createdAt in descending order
//     usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//     // Convert to CSV
//     let csvContent = "data:text/csv;charset=utf-8,Email,Name,Created At,Tier,Subscription ID,Status\n";
//     usersData.forEach(user => {
//       const row = `${user.email},${user.name},${user.createdAt},${user.tier},${user.subscription_id},${user.status}`;
//       csvContent += row + "\n";
//     });

//     // Trigger a download of the CSV file
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "subscribers_data.csv");
//     document.body.appendChild(link); // Required for FF
//     link.click();
//     document.body.removeChild(link); // Clean up

//   } catch (error) {
//     console.error("Error exporting subscriber data: ", error);
//   }
// }


// axios call to check status of a subscription
// async function checkSubscriptionStatus(subscriptionId) {
//   try {
//     const response = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/checksub`, { subscriptionId });
//     if (response.data.active) {
//         console.log('Subscription is active (' + response.data.subscription.status + ')', response.data.subscription);
//     } else {
//         console.log('Subscription is not active (' + response.data.subscription.status + ')', response.data.subscription);
//     }
//     return response.data.subscription.status
//   } catch (error) {
//       console.error('Error checking subscription status:', error);
//       return 'Error'
//   }
// }


// retrieve NPS score
// async function exportNPSFeedbackToCSV() {
//   try {
//     const feedbackCollection = await db.collection("feedback").get();
//     let feedbackData = [];
//     let scoreCounts = Array(11).fill(0); // Array to count scores from 0 to 10

//     feedbackCollection.forEach((doc) => {
//       const data = doc.data();
//       const feedbackRecord = {
//         email: doc.id,
//         score: data.score || 'N/A',
//         submittedAt: data.submittedAt ? data.submittedAt.toDate().toISOString() : 'N/A',
//         feedback: data.feedback || 'N/A',
//       };
//       feedbackData.push(feedbackRecord);

//       if (data.score !== undefined && data.score !== null) {
//         scoreCounts[data.score]++;
//       }
//     });

//     // Sort the data by submittedAt in descending order
//     feedbackData.sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt));

//     // Convert to CSV
//     let csvContent = "Email,Score,Submitted At,Feedback\n";
//     feedbackData.forEach(record => {
//       const row = `${record.email},${record.score},${record.submittedAt},${record.feedback}`;
//       csvContent += row + "\n";
//     });

//     // Trigger a download of the CSV file in the browser
//     const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "feedback_data.csv");
//     document.body.appendChild(link); // Required for FF
//     link.click();
//     document.body.removeChild(link); // Clean up

//     // Log the score counts
//     console.log("Score counts:");
//     for (let i = 0; i < scoreCounts.length; i++) {
//       console.log(`Score ${i}: ${scoreCounts[i]} times`);
//     }

//   } catch (error) {
//     console.error("Error exporting feedback data: ", error);
//   }
// }
// // Call the function
// exportNPSFeedbackToCSV();


// children is all components inside AuthProvider in App.js
export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [currentUserTier, setCurrentUserTier] = useState()
    const [currentTier, setCurrentTier] = useState()
    const [pricingPlan, setPricingPlan] = useState()
    const [UTMCode, setUTMCode] = useState()

    const history = useHistory();

    // creating document in database
    const createUserDocument = async (user, additionalData) => {
      
        if (!user) {
          return;
        }
    
        const userRef = db.doc(`users/${user.email}`)
        const snapshot = userRef.get()
    
        // if user id does not exist in database, create using set
        if (!(await snapshot).exists) {
    
          let currDate = new Date()
          let data = {
            name: user.displayName,
            uid: user.uid,
            tier: additionalData,
            createdAt: currDate,
            subscription_id: null,
            requests: {month: currDate.getMonth(), reqCount: 0}
          }
    
          try {
            await userRef.set(data);

            try {
              // Send the signup event to Google Analytics after the user is successfully created
              window.gtag && window.gtag('event', 'signup', {
                'event_category': 'Account',
                'event_label': 'Signup',
                'value': 1
              });
              console.log('New SignUp')
            } catch (error) {
              console.log('Error in tracking signup in Google Analytics', error);
            }

            // amplitude tracking
            amplitude.setUserId(user.uid); // Unique identifier for the user
            const identify = new amplitude.Identify()
              .set('email', user.email)
              .set('name', user.displayName)
              .set('createdAt', currDate);
            amplitude.identify(identify);

            amplitude.track('Sign Up');

          } catch (error) {
            console.log('Error in creating user', error);
          }

          try {
            await addEmailToCampaign(user.email, user.displayName, 'Existing Users🚗')
          } catch (error) {
            console.log('Error in adding user to email campaign', error);
          }
        } 
    }

    // calls routora api endpoint to add an email to group in mailerlite
    const addEmailToCampaign = async (email, name, group) => {

      let body = {
        email: email,
        name: name,
        group: group
      }

      Axios.post(`https://api.routora.com/addEmailToCampaign`, body).then((response) => {
          console.log('Status for Adding Email to Campaign: ' + response)
      }).catch((error) => {
          console.log(error)
      })

    }

    const getUserTier = async (user) => {
      if (!user) {
        return;
      }
  
      const userRef = db.doc(`users/${user.email}`)
      const snapshot = userRef.get()
  
      if ((await snapshot).exists) {
        let document = await db.doc(`users/${user.email}`).get()

        return document.data().tier
      }
    }

    const updateUserTier = async (user, newTier) => {
      if (!user) {
        return;
      }
  
      const userRef = db.doc(`users/${user.email}`)
      const snapshot = userRef.get()

      if ((await snapshot).exists) {
        let data = {
          tier: newTier
        }
  
        try {
          await userRef.update(data)
          setCurrentUserTier(newTier)
        } catch (error) {
          console.log('Error in updating user', error);
        }
      } 
    }

    const getSubId = async (user) => {
      if (!user) {
        return;
      }
  
      const userRef = db.doc(`users/${user.email}`)
      const snapshot = userRef.get()
  
      if ((await snapshot).exists) {
        let document = await db.doc(`users/${user.email}`).get()

        return document.data().subscription_id
      }
    }

    const updateSubId = async (user, subId) => {
      if (!user) {
        return;
      }
  
      const userRef = db.doc(`users/${user.email}`)
      const snapshot = userRef.get()

      if ((await snapshot).exists) {
        let data = {
          subscription_id: subId
        }
  
        try {
          await userRef.update(data)
        } catch (error) {
          console.log('Error in updating user', error);
        }
      } 
    }

    const addOnboarding = async (user, responses) => {
      if (!user) {
        return;
      }
    
      const userRef = db.doc(`users/${user.email}`);
      const snapshot = await userRef.get(); // Added await here
    
      if (snapshot.exists) {
        let data = {
          onboarding: responses
        };
    
        try {
          await userRef.update(data);

          // amplitude tracking
          const eventProperties = {
            segment: responses.segment,
            medium: responses.medium,
            organization: responses.organization
          };
          amplitude.track('Onboarding', eventProperties);

          // Set group properties for segment and medium
          amplitude.setGroup('segment', responses.segment);
          amplitude.setGroup('medium', responses.medium);

        } catch (error) {
          console.error('Error in updating user', error);
        }
      } 
    }  
    
    const addFeedback = async (user, responses) => {
      if (!user) {
        return;
      }
    
      const userRef = db.doc(`users/${user.email}`);
      const snapshot = await userRef.get(); // Added await here
    
      if (snapshot.exists) {
        let data = {
          cancelation: responses
        };
    
        try {
          await userRef.update(data);
        } catch (error) {
          console.error('Error in updating user', error);
        }
      } 
    } 

    // creating document in blog
    const createBlogDocument = async (title, content, image) => {

      let imageUrl = null;
      try {
        const imageRef = ref(storage, `images/${image.name}`);
        await uploadBytes(imageRef, image).then(async (snapshot) => {
          await getDownloadURL(snapshot.ref).then((url) => {
            console.log('image url -> ' + url)
            imageUrl = url
          });
        });
      } catch (error) {
        console.log('Error in uploading image to storage', error);
      }
  
      const userRef = db.doc(`blog/${title}`)
      const snapshot = userRef.get()
  
      // if userRef does not exist in database, create using set
      if (!(await snapshot).exists) {
  
        let currDate = new Date()
        let data = {
          title: title,
          content: content,
          timestamp: currDate,
          thumbnailUrl: imageUrl,
          viewCount: 0
        }
  
        try {
          await userRef.set(data);
        } catch (error) {
          console.log('Error in creating user', error);
        }
      } 
    }

    const getAllBlogData = async () => { 

      let allData = []
      const options = { month: 'short', day: '2-digit', year: 'numeric' }; // date format
      await db.collection("blog").orderBy("timestamp", "desc").get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {

            let documentData = {
              title: doc.data().title,
              content: doc.data().content,
              thumbnailUrl: doc.data().thumbnailUrl,
              timestamp: doc.data().timestamp.toDate().toLocaleString('en-US', options)
            }

            allData.push(documentData)
        
        });
      });

      console.log('ALL BLOG DATA - FIREBASE')
      console.log(allData)

      return allData
    }

    const getBlogData = async (blogTitle) => { 
      const userRef = db.doc(`blog/${blogTitle}`)
      const snapshot = userRef.get()
  
      const options = { month: 'short', day: '2-digit', year: 'numeric' }; // date format
      
      if ((await snapshot).exists) {
        let doc = await db.doc(`blog/${blogTitle}`).get()

        let documentData = {
          title: doc.data().title,
          content: doc.data().content,
          thumbnailUrl: doc.data().thumbnailUrl,
          timestamp: doc.data().timestamp.toDate().toLocaleString('en-US', options)
        }

        return documentData
      }

      return false
    }

    const updateBlogViewCount = async (blogTitle) => {
      const userRef = db.doc(`blog/${blogTitle}`)
      const snapshot = userRef.get()

      if ((await snapshot).exists) {
        let doc = await db.doc(`blog/${blogTitle}`).get()
        
        let viewCount = doc.data().viewCount
        let newViewCount = viewCount + 1
  
        try {
          await userRef.update({ viewCount: newViewCount })
        } catch (error) {
          console.log('Error in updating blog view count', error);
        }
      } 
    }

    const updateFleetClicks = async () => {
  
      const userRef = db.doc(`fleetManagement/clicks`)
      const snapshot = userRef.get()

      if ((await snapshot).exists) {
        let doc = await db.doc(`fleetManagement/clicks`).get()
        
        let count = doc.data().count
        let newCount = count + 1
  
        try {
          await userRef.update({ count: newCount })
        } catch (error) {
          console.log('Error in updating user', error);
        }
      } 
    }

    // USER FEEDBACK
    const checkFeedbackStatus = async (user) => {
      if (!user || !user.email) return false;
    
      try {
        const feedbackRef = db.doc(`feedback/${user.email}`);
        const feedbackSnap = await feedbackRef.get();
    
        if (feedbackSnap.exists) {
          console.log("Feedback already submitted.");
          return false;
        }
    
        const userRef = db.doc(`users/${user.email}`);
        const userSnap = await userRef.get();
    
        if (userSnap.exists) {
          const userData = userSnap.data();
          const accountCreationDate = new Date(userData.createdAt.seconds * 1000);
          const currentDate = new Date();
          const cutoffDate = new Date(currentDate.setDate(currentDate.getDate() - 10));

          console.log('accountCreationDate', accountCreationDate)
          console.log('cutoffDate', cutoffDate)
    
          if (accountCreationDate < cutoffDate) {
            console.log("Eligible for feedback.");
            return true;
          } else {
            console.log("Account too new for feedback.");
            return false;
          }
        }
      } catch (error) {
        console.error("Error checking feedback status: ", error);
        return false;
      }
    };
    const submitUserFeedback = async (user, score, feedback) => {
      if (!user || !user.email) return;
    
      try {
        const feedbackRef = db.doc(`feedback/${user.email}`);
        await feedbackRef.set({
          email: user.email,
          score: score,
          feedback: feedback,
          submittedAt: new Date() // Timestamp for when the feedback was submitted
        });
        console.log("Feedback successfully submitted.");
      } catch (error) {
        console.error("Error submitting feedback: ", error);
      }
    };

    // check the user's subscription status
    async function checkSubStatus(user) {

      let subscriptionId = await getSubId(user)
      if (subscriptionId == null) {
        return 'Null subscriptionID'
      }

      try {
        const response = await Axios.post(`${process.env.REACT_APP_AXIOS_BASE_URL}/checksub`, { subscriptionId });

        return response.data.subscription.status

      } catch (error) {
          console.error('Error checking subscription status:', error);
          return 'Error checking subscription status'
      }
    }

    // get affiliate codes
    const getAffiliateCodes = async () => { 

      const PROMO_CODES = {}
      await db.collection("affiliate").get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {

            PROMO_CODES[doc.data().code] = {price: doc.data().price, isYearly: doc.data().isYearly}
        
        });
      });

      return PROMO_CODES
    }

    // Track promo code usage
    const trackPromoCodeUsage = async (code, email) => {
      const db = firebase.firestore();
      const promoCodeRef = db.collection("affiliate").doc(code);
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed in JavaScript
      const currentYear = currentDate.getFullYear();
      const monthYear = `${currentMonth}-${currentYear}`;

      try {
        const promoCodeDoc = await promoCodeRef.get();

        if (!promoCodeDoc.exists) {
          console.log("Promo code does not exist in the database.");
          return;
        }

        const usageCollectionRef = promoCodeRef.collection("usage");
        const usageDocRef = usageCollectionRef.doc(monthYear);
        const usageDoc = await usageDocRef.get();

        if (!usageDoc.exists) {
          // Case 1: Collection does not exist, create it with the current month
          await usageDocRef.set({
            count: 1,
            emails: [email]
          });
          console.log(`Created new usage record for ${monthYear} with count 1 and added email.`);
        } else {
          // Case 2: Collection and current month exist, increment the count and add email to the array
          await usageDocRef.update({
            count: firebase.firestore.FieldValue.increment(1),
            emails: firebase.firestore.FieldValue.arrayUnion(email)
          });
          console.log(`Incremented usage count for ${monthYear} and added email.`);
        }
      } catch (error) {
        console.error("Error tracking promo code usage: ", error);
      }
    };


    

    // if there is a user on load, update currentUser
    useEffect(() => {
        stateChange()
    }, [])
    function stateChange() {
      firebase.auth().onAuthStateChanged(async user => {
        setCurrentUser(user)
        console.log("in useEffect, user:", user)
        createUserDocument(user, 0)

        if (user) { 
          amplitude.setUserId(user.uid); // Unique identifier for the user set each time user logs in (amplitude tracking)

          let userTier = await getUserTier(user)
          console.log('USER ' + user.displayName + ' tier: ', userTier) 
          setCurrentUserTier(userTier)

          // checks current path to make sure redirection only occurs when signing in
          let currentPath = (window.location.href).split('/').slice(-1)[0]
          console.log('CURRENT PATH: *' + currentPath + '*')

          // when signed in, user tier determines where to go
          if (currentPath === 'signin' || currentPath === 'signup') {
            if (userTier > 0) {
              history.push('/playground')
            } else {
              console.log('pricingPlan', pricingPlan)
              // if pricing plan is already selected from /pricing, redirect straight to /subscribe page (currently not working)
              // if (!pricingPlan) {
              //   history.push('/pricing')
              // } else {
              //   history.push('/subscribe')
              // }
              history.push('/pricing')
            }
          } else if (currentPath === 'extension-instructions') { // coming from extensions signup page (just google)
            history.push('/pricing-extension')
          }

        }
        
      })
    }

    function googleOauth() {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
          // The signed-in user info.
          var user = result.user;
          console.log("user", user);
          console.log("result", result);
        
        })
        .catch(function(error) {
          console.log(error);
        });
    }

    function facebookOauth() {
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().signInWithPopup(provider).then(function(result) {
        // The signed-in user info.
        var user = result.user;
        console.log("user", user);
        console.log("result", result);
      
      })
      .catch(function(error) {
        console.log(error);
      });
    }

    function signOut() {
        setCurrentTier(null)
        firebase.auth().signOut()
    }

    async function signUp(name, email, password) {
      // Return the promise from createUserWithEmailAndPassword
      return firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(async function(result) {
          await result.user.updateProfile({
            displayName: name
          });
          stateChange(); // Call stateChange after successful signup
          return null; // Return null to indicate no error occurred
        }).catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
    
          switch (errorCode) {
            case 'auth/email-already-in-use':
              return 'This email is already in use. Please try another email or sign in.';
            case 'auth/invalid-email':
              return 'Invalid email format. Please check the email address and try again.';
            case 'auth/operation-not-allowed':
              return 'Email/password accounts are not enabled. Please contact support by emailing info@routora.com';
            case 'auth/weak-password':
              return 'Password is too weak. Please use a stronger password.';
            case 'auth/network-request-failed':
              return 'Network error. Please check your internet connection and try again.';
            default:
              console.log(errorMessage);
              return errorMessage;
          }
        });
    }
    

    async function signIn(email, password) {
      // Return the promise from signInWithEmailAndPassword
      return firebase.auth().signInWithEmailAndPassword(email, password)
        .then(function(result) {
          // User is signed in
          console.log('Successfully signed in');
          stateChange();
          return null; // Return null to indicate no error occurred
        }).catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          
          if (errorCode === 'auth/wrong-password') {
            return 'Incorrect password. Please try again.';
          } else if (errorCode === 'auth/too-many-requests') {
            return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.';
          } else if (errorCode === 'auth/user-not-found') {
            return 'User does not exist, please make sure the email is correct. If you do not have an account please sign up.'
          } else if (errorCode === 'auth/invalid-email') {
            return 'Email is invalid. Please check again.';
          } else {
            console.log(errorMessage);
            return errorMessage;
          }
        });
        
    }
    
    

    function resetPassword(email) {
        firebase.auth().sendPasswordResetEmail(email)
    }

    async function utmHit(utm) {
      const userRef = db.doc(`utmTracking/${utm}`)
      const snapshot = userRef.get()

      if ((await snapshot).exists) {
        let doc = await db.doc(`utmTracking/${utm}`).get()
        
        let currDate = new Date();
        const day = String(currDate.getDate()).padStart(2, '0');  // padStart ensures it's always two digits
        const month = String(currDate.getMonth() + 1).padStart(2, '0');  // months are 0-indexed in JS
        const year = currDate.getFullYear();

        const dateString = `${month}-${day}-${year}`;
        console.log(dateString)

        if (doc.data().days && doc.data().days[dateString]) {

          let currHits = doc.data().days[dateString].hits

          try {

            // Increment hits in Firestore
            await updateDoc(userRef, {
              [`days.${dateString}.hits`]: currHits + 1
            });

          } catch (error) {
            console.log('Error in updating hit count', error);
          }

        } else {
          
          // Add date in Firestore
          await updateDoc(userRef, {
            [`days.${dateString}`]: {
              androidClicks: 0,
              hits: 1,
              iOSClicks: 0
            }
          });

        }
  

      } else {
        console.log('utm does not exist')
      }
    }

    async function utmLinkHit(utm, storeType) {
      const userRef = db.doc(`utmTracking/${utm}`)
      const snapshot = userRef.get()

      if ((await snapshot).exists) {
        let doc = await db.doc(`utmTracking/${utm}`).get()
        
        let currDate = new Date();
        const day = String(currDate.getDate()).padStart(2, '0');  // padStart ensures it's always two digits
        const month = String(currDate.getMonth() + 1).padStart(2, '0');  // months are 0-indexed in JS
        const year = currDate.getFullYear();

        const dateString = `${month}-${day}-${year}`;

        if (doc.data().days && doc.data().days[dateString]) {

          if (storeType == 'ios') {
            let currHits = doc.data().days[dateString].iOSClicks
            try {
              await updateDoc(userRef, {
                [`days.${dateString}.iOSClicks`]: currHits + 1
              });
  
            } catch (error) {
              console.log('Error in updating ios hit count', error);
            }
          } else if (storeType == 'android') {
            let currHits = doc.data().days[dateString].androidClicks
            try {
              await updateDoc(userRef, {
                [`days.${dateString}.androidClicks`]: currHits + 1
              });
  
            } catch (error) {
              console.log('Error in updating android hit count', error);
            }
          }

        } else {
          
          if (storeType == 'ios') {
            await updateDoc(userRef, {
              [`days.${dateString}`]: {
                androidClicks: 0,
                hits: 1,
                iOSClicks: 1
              }
            });
          } else if (storeType == 'android') {
            await updateDoc(userRef, {
              [`days.${dateString}`]: {
                androidClicks: 1,
                hits: 1,
                iOSClicks: 0
              }
            });
          }
          

        }
  

      } else {
        console.log('utm does not exist')
      }
    }


    // FLEET AUTHCONTEXT

    const createTeamProfile = async (user) => {
      if (!user) {
          console.error("No user provided");
          return;
      }

      const userRef = db.doc(`users/${user.email}`);
      const teamRef = userRef.collection("teams").doc(user.email);

      try {
          const snapshot = await teamRef.get();

          if (!snapshot.exists) {
              const currDate = new Date();
              const data = {
                  createdAt: currDate,
                  numDrivers: 0,
                  drivers: []
              };

              await teamRef.set(data);
              console.log("Team profile created successfully");
          } else {
              console.log("Team profile already exists");
          }
      } catch (error) {
          console.error("Error creating team profile", error);
      }
    };



    const value = {
        currentUser,
        currentTier,
        googleOauth,
        facebookOauth,
        signOut,
        signUp,
        signIn,
        resetPassword,
        setPricingPlan,
        pricingPlan,
        getUserTier,
        updateUserTier,
        setCurrentUserTier,
        getSubId,
        updateSubId,
        currentUserTier,
        createBlogDocument,
        getAllBlogData,
        getBlogData,
        updateBlogViewCount,
        setUTMCode,
        UTMCode,
        utmHit,
        utmLinkHit,
        updateFleetClicks,
        addOnboarding,
        addFeedback,
        checkFeedbackStatus,
        submitUserFeedback,
        checkSubStatus,
        getAffiliateCodes,
        trackPromoCodeUsage,
        createTeamProfile
    }

    // every page will have access to data in value, AuthContext.Provider wrapped around children, which is every component inside AuthContext in app.js
    return (
        <AuthContext.Provider value={value}>
          {children}
        </AuthContext.Provider>
      )
}